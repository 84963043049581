.teaser {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.teaser .div {
  background-color: #fff;
  border: 1px;
  width: 375px;
  height: 812px;
  position: relative;
}

.teaser .frame {
  background: linear-gradient(#a528a3 0%, #860e84 100%);
  border: 1px solid #c1a4c0;
  border-radius: 43px;
  width: 86px;
  height: 86px;
  position: absolute;
  top: 637px;
  left: 145px;
  box-shadow: 2px 3px 26px #c036be30;
}

.teaser .vectorS3 {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 26px;
  left: 26px;
}

.teaser .vector {
  width: 28px;
  height: 21px;
  position: absolute;
  top: 32px;
  left: 28px;
}

.teaser .overlap-group-wrapper {
  width: 335px;
  height: 371px;
  position: absolute;
  top: 193px;
  left: 20px;
}

.teaser .overlap-group {
  background-position: 50%;
  background-size: cover;
  width: 375px;
  height: 431px;
  position: relative;
  top: -27px;
  left: -20px;
}

.overlap-group1 {
  background-image: url("rectangle-68.a038a6b0.png");
}

.overlap-group2 {
  background-image: url("rectangle-69.cf708dbd.png");
}

.overlap-group3 {
  background-image: url("rectangle-70.cb55fc7f.png");
}

.teaser .rectangle {
  background: linear-gradient(#1f1f1f00 0%, #1f1f1f 100%);
  border-radius: 0 0 30px 30px;
  width: 335px;
  height: 371px;
  position: absolute;
  top: 27px;
  left: 20px;
}

.teaser .text-wrapper {
  color: #eaeaea;
  letter-spacing: 0;
  text-align: center;
  width: 275px;
  font-family: Poppins, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  position: absolute;
  top: 263px;
  left: 50px;
}

.teaser .text-wrapper2 {
  width: 335px;
  top: 229px;
  left: 20px;
}

.teaser .vector-1 {
  width: 92px;
  height: 129px;
  position: absolute;
  top: 667px;
  left: 283px;
}

.teaser .vector-2 {
  width: 53px;
  height: 101px;
  position: absolute;
  top: 682px;
  left: 0;
}

.teaser .vector-3 {
  width: 83px;
  height: 76px;
  position: absolute;
  top: 0;
  left: 292px;
}

.teaser .vector-4 {
  width: 62px;
  height: 64px;
  position: absolute;
  top: 0;
  left: 0;
}

.teaser .text-wrapper-2 {
  color: #860e84;
  letter-spacing: 0;
  text-align: center;
  font-family: Andika New Basic, Helvetica;
  font-size: 25px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 74px;
  left: 89px;
}

.teaser .sms {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 25px;
  left: 26px;
}

.teaser .frameS3 {
  align-items: flex-start;
  gap: 20px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.teaser .groupS3 {
  background: linear-gradient(#a528a3 0%, #860e84 100%);
  border: 1px solid #c1a4c0;
  border-radius: 43px;
  min-width: 86px;
  height: 86px;
  position: relative;
  box-shadow: 2px 3px 26px #c036be30;
}

.teaser .overlap {
  width: 283px;
  height: 159px;
  position: absolute;
  top: 637px;
  left: 92px;
}

/*# sourceMappingURL=index.d3abcddb.css.map */
