.teaser {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.teaser .div {
  background-color: #ffffff;
  border: 1px none;
  height: 812px;
  position: relative;
  width: 375px;
}

.teaser .frame {
  background: linear-gradient(180deg, rgb(164.69, 39.8, 162.61) 0%, rgb(134, 14, 132) 100%);
  border: 1px solid;
  border-color: #c1a4c0;
  border-radius: 43px;
  box-shadow: 2px 3px 26px #c036be30;
  height: 86px;
  left: 145px;
  position: absolute;
  top: 637px;
  width: 86px;
}

.teaser .vectorS3 {
  height: 32px;
  left: 26px;
  position: absolute;
  top: 26px;
  width: 32px;
}

.teaser .vector {
  height: 21px;
  left: 28px;
  position: absolute;
  top: 32px;
  width: 28px;
}

.teaser .overlap-group-wrapper {
  height: 371px;
  left: 20px;
  position: absolute;
  top: 193px;
  width: 335px;
}

.teaser .overlap-group {
  background-position: 50% 50%;
  background-size: cover;
  height: 431px;
  left: -20px;
  position: relative;
  top: -27px;
  width: 375px;
}

.overlap-group1 {
  background-image: url(../static/img/rectangle-68.png);
}

.overlap-group2 {
  background-image: url(../static/img/rectangle-69.png);
}

.overlap-group3 {
  background-image: url(../static/img/rectangle-70.png);
}

.teaser .rectangle {
  background: linear-gradient(180deg, rgba(31, 31, 31, 0) 0%, rgb(31, 31, 31) 100%);
  border-radius: 0px 0px 30px 30px;
  height: 371px;
  left: 20px;
  position: absolute;
  top: 27px;
  width: 335px;
}

.teaser .text-wrapper {
  color: #eaeaea;
  font-family: "Poppins", Helvetica;
  font-size: 32px;
  font-weight: 600;
  left: 50px;
  letter-spacing: 0;
  line-height: 39px;
  position: absolute;
  text-align: center;
  top: 263px;
  width: 275px;
}

.teaser .text-wrapper2 {
  left: 20px;
  top: 229px;
  width: 335px;
}

.teaser .vector-1 {
  height: 129px;
  left: 283px;
  position: absolute;
  top: 667px;
  width: 92px;
}

.teaser .vector-2 {
  height: 101px;
  left: 0;
  position: absolute;
  top: 682px;
  width: 53px;
}

.teaser .vector-3 {
  height: 76px;
  left: 292px;
  position: absolute;
  top: 0;
  width: 83px;
}

.teaser .vector-4 {
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 62px;
}

.teaser .text-wrapper-2 {
  color: #860e84;
  font-family: "Andika New Basic", Helvetica;
  font-size: 25px;
  font-weight: 700;
  left: 89px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 74px;
}

.teaser .sms {
  height: 34px;
  left: 26px;
  position: absolute;
  top: 25px;
  width: 34px;
}

.teaser .frameS3 {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: fit-content;
}

.teaser .groupS3 {
  background: linear-gradient(180deg, rgb(164.69, 39.8, 162.61) 0%, rgb(134, 14, 132) 100%);
  border: 1px solid;
  border-color: #c1a4c0;
  border-radius: 43px;
  box-shadow: 2px 3px 26px #c036be30;
  height: 86px;
  min-width: 86px;
  position: relative;
}

.teaser .overlap {
  height: 159px;
  left: 92px;
  position: absolute;
  top: 637px;
  width: 283px;
}